const getScrollProgress = ( contentEl, offset=0 ) =>
{
    if ( typeof window === 'undefined' || !window.document ) return 0;
    const contentElTop = contentEl ? contentEl.offsetTop : 0;
    const contentElHeight = contentEl ? contentEl.clientHeight : 0;
    const { documentElement } = window.document;

    const scrolled = Math.max( 0, documentElement.scrollTop - contentElTop + offset );
    const totalHeight = contentElHeight - documentElement.clientHeight;
    
    return Math.min( 1, scrolled / totalHeight );
}
export default getScrollProgress;