import React from 'react';
import PropTypes from 'prop-types';
import filterId from '../../../utilities/filterId'
import { testAttributes } from './Constants';

const GuideSection = React.forwardRef( ({id, children}, ref) => (
    <section id={filterId(id)} ref={ref} data-test={testAttributes.GUIDE_SECTION}>
        {children}
    </section>
));

GuideSection.propTypes = {
    /** id: enables linking to this section (will be filtered) */
    id: PropTypes.string.isRequired,
    /** children: expecting one <h2> or <h3> component, and any number of paragraphs or mixed content */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.elementType,
        PropTypes.arrayOf(PropTypes.any)
    ])
}
GuideSection.defaultProps = {
    children: ''
}

export default GuideSection;

/* @TODO: how to use parser functions (in GuideContainer):

    const [ parseResult, guideContent ] = fnParseWith( testHtml, {
        guideSectionParser: fnGuideSectionParser
    })
    const guideIndex = parseResult.guideSectionParser.return.index();
    const { preamble, guideSections } = guideSectionBuilder( guideContent )

*/