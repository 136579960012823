/* @NOTE: disabling certain linter rules, 
    because enzyme mount() won't work in Jenkins/SONAR,
    so we use workarounds to make more lines testable with shallow()
*/
import React from 'react';
import PropTypes from 'prop-types';
import {Scrollspy} from "@makotot/ghostui";
import Breadcrumb from '../../simple/Breadcrumb';
import PostPageHeader from '../PostPage/PostPageHeader';
import ArticleSource from '../../simple/ArticleSource';
import GuideScrollIndex from '../../composite/GuideScrollIndex';
import GuideProgressBar from '../../simple/GuideProgressBar';
import { fngetMorePosts } from '../PostPage';
import getScrollProgress from '../../../utilities/getScrollProgress';
import GridContainer from '../../imported/GridContainer';
import ShareButtons from '../../simple/ShareButtons';
import { articleSourcesId } from '../../composite/Footnote/Constants';
import { testAttributes, btnLabel } from './Constants';
import { build } from '../../../Constants.json';

import {
    GuideComponent,
    guidePortion,
    guideIntro,
    guideMain,
    guideRelated,
    guideStickyNav,
    guideStickyProgress,
    guideScrollSections,
    guideMainPosition,
    articleMain,
    content,
    breadcrumbContainer,
    titlePostRelated,
} from './GuidePage.module.scss';


const GuidePage = ({ guideInfo, gridTitle} ) =>
{
    const { guideSections } = guideInfo.infoGuide;
    const phArray = new Array( guideSections.length ).fill(true);
    const sectionRefs = phArray.map( () => React.createRef(null) );
    const guideContentRef = React.createRef(null);
    
    const preppedGuideSections = guideSections.map( (gs, ix) =>
        (
            React.cloneElement(
                gs,
                { ref: elm =>
                {
                    sectionRefs[ix].current = elm
                }
                }
            )
        )
    );
    return (
        <div
            className={`${GuideComponent} site-content article-${guideInfo.databaseId}`}
            id="primary"
            data-test={testAttributes.GUIDEPAGE_MAIN}>
            <article
                className={`${articleMain}`}
                itemScope itemType="https://schema.org/Article"
                data-test={testAttributes.GUIDEPAGE_ARTICLE}
            >
                <div className={`${guidePortion} ${guideIntro}`}>
                    <div className={content}>
                        <div className={breadcrumbContainer}>
                            <Breadcrumb
                                breadcrumbs={guideInfo.breadcrumbs}
                                data-test={testAttributes.GUIDEPAGE_BREADCRUMB}
                                className="breadcrumb"
                            />
                        </div>

                        <PostPageHeader
                            title={guideInfo.infoPost.title}
                            image={guideInfo.infoPost.image}
                            introContent={guideInfo.infoGuide.preamble}
                            headerInfo={guideInfo.infoPost.headerInfo}
                        />
                    </div>
                </div>

                <div
                    className={`${guidePortion} ${guideMain}`}
                    itemProp="articleBody"
                    data-test={testAttributes.GUIDEPAGE_CONTENT}
                >
                    <div className={guideMainPosition}>
                        <Scrollspy sectionRefs={sectionRefs} offset={-220}>
                            {({currentElementIndexInViewport}) => (
                                <>
                                    <div className={guideStickyNav}>
                                        <GuideScrollIndex 
                                            guideIndex={guideInfo.infoGuide.guideIndex} 
                                            titleItem={guideInfo.infoGuide.navTitle} 
                                            currentElementIndex={currentElementIndexInViewport} />
                                        <ShareButtons
                                            data={guideInfo.sharebutton.data}
                                            url={guideInfo.sharebutton.url}
                                            data-test={testAttributes.GUIDEPAGE_SHAREBUTTON}
                                            direction="horizontal"
                                        />

                                    </div>
                                    <div className={guideStickyProgress}>
                                        <GuideProgressBar length={getScrollProgress( guideContentRef.current, 100 )} />
                                    </div>
                                    <div className={guideScrollSections} ref={guideContentRef}>
                                        <div className="editorialBody">
                                            { preppedGuideSections }
                                        </div>
                                        { guideInfo.articleSourceList?.length ? (
                                            <ArticleSource
                                                id={articleSourcesId}
                                                sourceList={guideInfo.articleSourceList}
                                            />
                                        ) : null }
                                    </div>
                                </>
                            )}
                        </Scrollspy>
                    </div>
                </div>

                <div
                    className={`${guidePortion} ${guideRelated}`}
                >
                    <div className={content}>
                        <h2
                            data-test={testAttributes.GUIDEPAGE_TITLREADMORE}
                            className={titlePostRelated}
                        >
                            {gridTitle}
                        </h2>
                        <GridContainer
                            queryArgs={{
                                posts: guideInfo.postsRelated,
                                total_found: guideInfo.postsRelated.length
                            }}
                            type="button"
                            gridLimit={build.defaults.POSTS_PER_PAGE}
                            getMorePosts={fngetMorePosts}
                            btnLabel={btnLabel}
                            data-test={testAttributes.GUIDEPAGE_GRIDCONTAINER}
                            containerId={build.defaults.GRID_CONTAINER_ID}
                        />
                    </div>
                </div>
            </article>
        </div>
    )
}
GuidePage.propTypes = {
    /** guideInfo, data for GraphQL  */
    guideInfo: PropTypes.objectOf(PropTypes.any).isRequired,
    /** title, for grid container default Read More  */
    gridTitle: PropTypes.string,
};

GuidePage.defaultProps = {
    gridTitle: 'Read More',
};

export default GuidePage;
