import guideSectionParser from '.'

// eslint-disable-next-line import/prefer-default-export
export const fnGuideSectionParser = () =>
{
    let guideIndex = [];
    return {
        fn : guideSectionParser,
        callback: headOb => 
        {
            guideIndex = [...guideIndex, headOb];
        },
        return: {
            index: () => guideIndex
        }
    }
}
