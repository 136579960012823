import PropTypes from 'prop-types';
import React from 'react';
import { testAttributes } from './Constants';
import {
    progressMainWrapper,
    progressMainStyle,
} from './GuideProgressBar.module.scss';

const GuideProgressBar = ({ length }) => 
    (
        <div data-test={testAttributes.GUIDE_PROGRESS_BAR_CONTAINER} className={progressMainWrapper}>
            <div data-test={testAttributes.GUIDE_PROGRESS_BAR} className={progressMainStyle} style={{ height: `${100 * length}%` }} />
        </div>
    );

GuideProgressBar.propTypes = {
    /** length proportional to its container */
    length : PropTypes.number,
};

GuideProgressBar.defaultProps = {
    length: 0
};

export default GuideProgressBar;