/* eslint-disable react/prop-types */
import React from 'react';
import { domToReact, attributesToProps } from 'html-react-parser';
import filterId from '../../../filterId'

import { wpChapterClass,wpSectionClass, dataGuideIdProp } from '../Constants';

const textReduction = (str, child) =>
{
    if ( child?.type === 'text' )
    {
        const trimmed = child.data.trim();
        if ( !str.length ) return trimmed;
        if ( !trimmed.length ) return str;
        return `${str} ${trimmed}`;
    }
    return child && child.children && child.children.reduce( textReduction, str );
}

const guideSectionParser = callback => ({name,type,attribs,children}) => 
{
    if ( type !== 'tag' ) return null;
    if ( !name || ( name !== 'h2' && name !== 'h3' ) ) return null;
    const classNames = attribs?.class?.split(' ') || [];
    let typecomponent = null;
    let elm = ''  
    if (classNames.some((className) => className === wpChapterClass))
    {
        typecomponent='chapter';
        elm = 'h2'
    } 
    else if (classNames.some((className) => className === wpSectionClass))
    {
        typecomponent='section';
        elm = 'h3'
    }
    if(typecomponent===null) return null;

    const id = filterId(children.reduce( textReduction, ''));
    const headChildren = domToReact( children );
    callback( {
        id,
        children: headChildren,
        type: typecomponent
    } );

    return React.createElement(
        elm,
        {...attributesToProps(attribs), [dataGuideIdProp]: id},
        headChildren
    );
}
export default guideSectionParser;
