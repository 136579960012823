import {wpChapterClass,wpSectionClass} from '../../../utilities/parses/parseWith/Constants'

export const testAttributes = {
    MAIN_CONTAINER: "guide_main_scroll_container",
    NAV_GUIDE_MAIN: "nav_guide_main",
    NAV_GUIDE_TITLE: "nav_guide_title",
    NAV_GUIDE_CHILD_TITLE: "nav_guide_child_title",
    NAV_GUIDE_DROPDOWN: "nav_guide_dropdown",
    NAV_GUIDE_ITEM_DROPDOWN: "nav_guide_item_dropdown",
    NAV_GUIDE_MAIN_ITEM: "nav_guide_main_item",
    NAV_GUIDE_MAIN_CHILD_ITEM: "nav_guide_main_child_item",
    NAV_GUIDE_MOBILE_DROPDOWN: "nav_guide_mobile_dropdown",
};

export const scrollspyAttributes = {
    ID: 'guide-scrollspy',
    DEFAULT_TITLE: 'EXPLORE GUIDE'
}

const parsx = `
<p>Lorem</p>`;

export const sampleHTML = `
<h1>Guide Title</h1>
<h2 class="${wpChapterClass}">Guide <i>begins here</i></h2>
${parsx}
<h3 class="${wpSectionClass}">Section 1.1</h3>
${parsx}
<h3 class="${wpSectionClass}">Section 1.2</h3>
${parsx}
<h2 class="${wpChapterClass}">Guide Chapter 2</h2>
${parsx}
<h3 class="${wpSectionClass}">Section 2.1</h3>
${parsx}
<h3 class="${wpSectionClass}">Section 2.2</h3>
${parsx}
`;

export const sampleHTMLAlt1 = `
<h1>Guide Title</h1>
<h2 class="${wpChapterClass}">Guide <i>begins here</i></h2>
${parsx}
<h2 class="${wpChapterClass}">Guide Chapter 2</h2>
${parsx}
<h3 class="${wpSectionClass}">Section 2.1</h3>
${parsx}
`;
export const sampleHTMLAlt2 = `
<h1>Guide Title</h1>
<h3 class="${wpSectionClass}">Section 0.1</h3>
${parsx}
<h2 class="${wpChapterClass}">Guide <i>begins here</i></h2>
${parsx}
<h2 class="${wpChapterClass}">Guide Chapter 2</h2>
${parsx}
<h3 class="${wpSectionClass}">Section 2.1</h3>
${parsx}
`;