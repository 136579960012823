import React from 'react';
import GuideSection from '.';
import {dataGuideIdProp} from '../../../utilities/parses/parseWith/Constants'

const guideSectionBuilder = elementsArray =>
{
    let preamble = [];
    let guideSections = [];
    let buffer = [];
    let nextSectionId = null;

    const addBufferToGuide = () =>
    {
        guideSections = [
            ...guideSections,
            <GuideSection id={nextSectionId} key={`sectionKey-${guideSections.length}`}>
                {[...buffer]}
            </GuideSection>
        ]
    }
    let keyIx = 0;
    elementsArray.forEach( (elm) =>
    {
        if ( typeof elm === 'object' && elm.props?.[ dataGuideIdProp ] )
        {
            if ( nextSectionId === null )
            {
                preamble = [...buffer]
            }
            else
            {
                addBufferToGuide( )
            }
            buffer = [React.cloneElement(
                elm,
                {key: `guideSectionElement_${keyIx}`}
            )];
            nextSectionId = elm.props[ dataGuideIdProp ];
        }
        else
        {
            buffer = [...buffer, elm?.type ? React.cloneElement(
                elm,
                {key: `guideSectionElement_${keyIx}`}
            ) : elm ];
        }
        keyIx += 1;
    })
    addBufferToGuide()
    return {
        preamble,
        guideSections
    }
}

export default guideSectionBuilder;