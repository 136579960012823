import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { testAttributes } from './Constants'

import {
    dropdown,
    notDropdown,
    dropdownContent,
    navContainer,
    articleSourceWrapper,
    dropBtn,
    dropCont,
    btnDropClose,
    btnDropOpen,
    guideNavIndex,
    isOpened,
    isClosed,
    guideTitle,
    dropButton,
    activeParent,
    parentOfActive,
    activeItem,
} from './GuideIndex.module.scss';

const GuideIndex = ({ guideIndex, titleItem, currentElementIndex }) => 
{   
    const buildNavArray = React.useCallback(() => 
    {
        let localNavArray = []
        guideIndex.forEach( (navOb,ix) =>
        {
            if ( navOb.type === 'chapter' )
            {
                localNavArray = [...localNavArray, {
                    ...navOb,
                    navChildren: [],
                    navIx: ix
                }]
            }
            else
            {
                const lastIx = localNavArray.length-1;
                if ( localNavArray[lastIx] === undefined ) return true;
                const lastNavChildren = localNavArray[lastIx].navChildren;
                localNavArray[lastIx].navChildren = [...lastNavChildren, {
                    ...navOb,
                    navIx:ix,
                }]
            }
            return null;
        })
        return localNavArray;
    },[guideIndex]);
    
    const navArray = buildNavArray();
    const [manualMode, setManualMode] = useState(false);
    const [toggledButtonId, setToggledButtonId] = useState(null);
    const getAutoToggledStatus = ({ navChildren, navIx }) => currentElementIndex === navIx || navChildren.some( ch => currentElementIndex === ch.navIx )
    const toggleButton = ({id, navChildren, navIx }) =>
    {
        const previousManualMode = manualMode;
        setManualMode(true);

        if ( !previousManualMode && getAutoToggledStatus({navChildren, navIx }))
        {
            setToggledButtonId('');
            return true;
        }

        return id === toggledButtonId
            ? setToggledButtonId('')
            : setToggledButtonId(id);
    }
    const [toggledGuide, setToggledGuide] = useState(false);
    
    const closeTab = () => setToggledGuide(false);
    return (
        <nav
            role="navigation"
            data-test={testAttributes.NAV_GUIDE_MAIN}
            className={guideNavIndex}
        >
            <button
                className={`${dropButton} ${guideTitle} ${
                    toggledGuide ? btnDropOpen : btnDropClose
                } `}
                onClick={() => setToggledGuide(!toggledGuide)}
                type="button"
                aria-label={titleItem}
                data-test={testAttributes.NAV_GUIDE_DROPDOWN}
            >
                {titleItem}
            </button>
            <ul
                className={`${navContainer}  ${
                    toggledGuide ? isOpened : isClosed
                }`}
                data-test={testAttributes.NAV_GUIDE_CONTENT}
            >
                {navArray?.map(
                    ({ id, children, navChildren, navIx }) => 
                    {
                        const isToggled = (manualMode) ?
                            id === toggledButtonId : getAutoToggledStatus({ navChildren, navIx })
                        return (
                            <li
                                key={id}
                                data-test={
                                    testAttributes.NAV_GUIDE_MAIN_ITEM
                                }
                                type="button"
                                aria-label={id.replace(/\-/g,' ')}
                                className={`${
                                        navChildren?.length
                                            ? dropdown
                                            : notDropdown
                                    } ${
                                        currentElementIndex === navIx ? activeParent : ''
                                    } ${
                                        navChildren.some( ch => currentElementIndex === ch.navIx ) ? parentOfActive : ''
                                    }`}
                               
                            >
                                {navChildren?.length > 0 && (
                                    <button
                                        data-test={
                                            testAttributes.NAV_GUIDE_ITEM_DROPDOWN
                                        }
                                        className={`${dropBtn} ${
                                            isToggled
                                                ? btnDropOpen
                                                : btnDropClose
                                        } 
                                        `}
                                        onClick={() =>
                                            toggleButton({id, navChildren, navIx })
                                        }
                                        type="button"
                                        aria-label="Toggle Menu"
                                    />
                                )}
                                <a
                                    className="itemScroll"
                                    href={`#${id}`}
                                    onClick={closeTab}
                                >
                                    {children}
                                </a>

                                {navChildren?.length > 0 && (
                                    <div className={dropCont}>
                                        <ul
                                            data-test={
                                                testAttributes.NAV_GUIDE_CHILD_CONTENT
                                            }
                                            className={`${dropdownContent} ${articleSourceWrapper}  ${
                                                isToggled
                                                    ? isOpened
                                                    : isClosed
                                            }`}
                                        >
                                            {navChildren.map(
                                                (child)=> (
                                                    <li
                                                        key={
                                                            child.id
                                                        }
                                                        data-test={
                                                            testAttributes.NAV_GUIDE_MAIN_CHILD_ITEM
                                                        }
                                                        className={`${currentElementIndex === child.navIx ? activeItem : null }`}
                                                    >
                                                        <a
                                                            href={`#${child.id}`}
                                                            onClick={closeTab}
                                                        >
                                                            {child.children}
                                                        </a>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                )}
                            </li>
                        );
                    }
                )}
            </ul>
        </nav>
    );
};

GuideIndex.propTypes = {
    /** Title of accordion guide ( required ) */
    titleItem: PropTypes.string.isRequired,
    /** Array of index titles */
    guideIndex: PropTypes.arrayOf( PropTypes.object ).isRequired,
    /** Index of highlighted navigation section (e.g. currently in the viewport) */
    currentElementIndex: PropTypes.number
};
GuideIndex.defaultProps = {
    currentElementIndex: 0
}
export default GuideIndex;
