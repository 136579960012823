// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var scrollTopValue = "86";
export var guideNavIndex = "v_bl";
export var guideTitle = "v_bm";
export var navContainer = "v_bn";
export var isOpened = "v_bp";
export var isClosed = "v_bq";
export var menuItem = "v_br";
export var articleSourceWrapper = "v_4";
export var active = "v_bs";
export var dropdown = "v_bt";
export var notDropdown = "v_bv";
export var activeParent = "v_bw";
export var parentOfActive = "v_bx";
export var btnDropClose = "v_by";
export var dropdownContent = "v_bz";
export var activeItem = "v_bB";
export var dropBtn = "v_bC";
export var btnDropOpen = "v_bD";
export var dropButton = "v_bF";
export var scrollContainer = "v_bG";