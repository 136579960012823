// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var content = "l_h";
export var guidePortion = "l_H";
export var guideIntro = "l_J";
export var guideStickyNav = "l_K";
export var guideStickyProgress = "l_L";
export var breadcrumbContainer = "l_j";
export var breadcrumb = "l_k";
export var GuideComponent = "l_M";
export var articleMain = "l_p";
export var titlePostRelated = "l_m";
export var guideMain = "l_N";
export var guideScrollSections = "l_P";
export var guideMainPosition = "l_Q";